import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { thunkErrorHandler } from '../../common/utils/thunkErrorHandler';
import { apiAnalysis } from './API/analysisAPI';
import {
  AddChannelValuesType,
  AnalysisCustomerState,
  AnalysisItem,
  GetPaginationResponse,
  SortData,
} from './common/types/types';

const getChannels = createAsyncThunk<AnalysisCustomerState, GetPaginationResponse>(
  'analysis/getUserData',
  async (arg, { dispatch, rejectWithValue }) => {
    try {
      const res = await apiAnalysis.getUserChannels(arg);
      return res.data;
    } catch (e) {
      thunkErrorHandler(e, dispatch);
      return rejectWithValue(null);
    }
  }
);

const setAddChannelInfo = createAsyncThunk<{ link: string; telegramName: string }, AddChannelValuesType>(
  'analysis/setAddChannelInfo',
  async (payload: AddChannelValuesType, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiAnalysis.postDataAnalysis(payload);

      return response.data as { link: string; telegramName: string };
    } catch (e) {
      thunkErrorHandler(e, dispatch);
      return rejectWithValue(null);
    }
  }
);

const initialState: AnalysisCustomerState = {
  totalCount: 0,
  pagesCount: 0,
  page: 1,
  size: 0,
  items: [] as AnalysisItem[],
  error: null,
  loading: false,
  sortData: {
    field: '',
    sortDir: '',
  },
  link: '',
};

export const analysisSlice = createSlice({
  name: 'analysis/userData',
  initialState,
  reducers: {
    /* setCurrentPage: (state, action: PayloadAction<number>) => {
            state.page = action.payload;
        },*/
    setSortData: (state, action: PayloadAction<SortData>) => {
      state.sortData = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getChannels.pending, state => {
        state.loading = true;
      })
      .addCase(getChannels.fulfilled, (state, action) => {
        const { totalCount, pagesCount, page, size, items } = action.payload;
        state.totalCount = totalCount;
        state.pagesCount = pagesCount;
        state.page = page;
        state.size = size;
        state.items = items;
        state.loading = false;
      });
  },
});

/*export const { setCurrentPage } = analysisSlice.actions;*/
export const { setSortData } = analysisSlice.actions;

export const thunkAdvt = { getChannels, setAddChannelInfo };
